import React from "react";
//import _ from 'lodash';




function Office() {


    /*Date.prototype.getWeek = function() {
        var date = new Date(this.getTime());
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                              - 3 + (week1.getDay() + 6) % 7) / 7);
      }*/

    /*
    const date = new Date();
    //const week = date.getWeek();
    const week = 35;

    

    const [data,setData]=useState([]);
    // API call to server to fetch office schedule


        useEffect(() => {
                fetch(`https://my.trok.fi/api/getOne/${week}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }
                )
                    .then(function(response){

                        return response.json();
                    })
                    .then(function(myJson) {

                        if (_.isEmpty(myJson)) {
                            setData({
                                "monday": "-",
                                "tuesday": "-",
                                "wednesday": "-",
                                "thursday": "-",
                                "friday": "-",
                                "info": "-",
                            })
                        } else {
                            setData(myJson);
                        }


                    });
          }, []);

          */

    

    return (
        <div className="container">

            <p>TROKin toimisto sijaitsee Lemminkäisenkadun kampuksella tilassa LEM_B013. Päivitämme jokaisen viikon aukioloajat somekanavissamme ennen jokaisen viikon alkua.</p>
            <p>Tervetuloa oleskelemaan toimistolle kahvikupposen kanssa oven ollessa auki!</p>
            <p>Toimisto toimii kaikille opiskelijoille päivystysaikana ajanviettopaikkana sekä jäsenpalvelupisteenä.</p>
            <p>Toimistolla voitte kavereiden kanssa ottaa luentojen välissä kupin kahvia, tehdä koulutöitä tai muuten vaan hengailla hallituslaisten kanssa.</p>
            <p>Toimistolla myydään tapahtumalippuja, haalarimerkkejä ja muita oheistuotteita. Tule kurkkaamaan mitä kaikkea kivaa toimistolta löytyy!</p>
            <p>Nähdään toimistolla!</p>

            <hr />
        </div>
  );
    
}

export default Office;