import React from 'react';

function ErrorPage() {
    return(

        <div>   
            <h1>Virheellinen osoite</h1>
            <a href="/"><button>Palaa etusivulle</button></a>
        </div>
    )
}

export default ErrorPage;